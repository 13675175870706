// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-pages-privacy-mdx": () => import("./../../../content/pages/privacy.mdx" /* webpackChunkName: "component---content-pages-privacy-mdx" */),
  "component---content-pages-terms-mdx": () => import("./../../../content/pages/terms.mdx" /* webpackChunkName: "component---content-pages-terms-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

