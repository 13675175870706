import React from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Row from 'react-bootstrap/Row'
import Tooltip from 'react-bootstrap/Tooltip'

import { FaFacebookF } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaPinterest } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'

const SocialLinks = () => {
  function triggerRootClose() {
    document?.body?.click()
  }
  return (
    <Row className="pb-3 g-4 justify-content-center text-nowrap">
      <Col className="flex-grow-0">
        <OverlayTrigger
          rootClose
          placement="bottom"
          overlay={<Tooltip id={'tooltip-facebook'}>FACEBOOK</Tooltip>}
        >
          <Button href={'https://facebook.com/artlab.eco'} variant="icon" onClick={triggerRootClose}>
            <FaFacebookF size="32" />
          </Button>
        </OverlayTrigger>
      </Col>
      <Col className="flex-grow-0">
        <OverlayTrigger
          rootClose
          placement="bottom"
          overlay={<Tooltip id={'tooltip-instagram'}>INSTAGRAM</Tooltip>}
        >
          <Button href={'https://instagram.com/artlab.eco'} variant="icon" onClick={triggerRootClose}>
            <FaInstagram size="32" />
          </Button>
        </OverlayTrigger>
      </Col>
      <Col className="flex-grow-0">
        <OverlayTrigger
          rootClose
          placement="bottom"
          overlay={<Tooltip id={'tooltip-pinterest'}>PINTEREST</Tooltip>}
        >
          <Button href={'https://pinterest.com/artlab_eco'} variant="icon" onClick={triggerRootClose}>
            <FaPinterest size="32" />
          </Button>
        </OverlayTrigger>
      </Col>
      <Col className="flex-grow-0">
        <OverlayTrigger
          rootClose
          placement="bottom"
          overlay={<Tooltip id={'tooltip-twitter'}>TWITTER</Tooltip>}
        >
          <Button href={'https://twitter.com/artlab_eco'} variant="icon" onClick={triggerRootClose}>
            <FaTwitter size="32" />
          </Button>
        </OverlayTrigger>
      </Col>
      <Col className="flex-grow-0">
        <OverlayTrigger
          rootClose
          placement="bottom"
          overlay={<Tooltip id={'tooltip-linkedin'}>LINKEDIN</Tooltip>}
        >
          <Button href={'https://linkedin.com/company/artlab-eco'} variant="icon" onClick={triggerRootClose}>
            <FaLinkedinIn size="32" />
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  )
}

export default SocialLinks
