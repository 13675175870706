import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { motion, useAnimation } from 'framer-motion'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import './contact-form.scss'

const formEncode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const movAppear = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const controls = useAnimation()
  useEffect(() => {
    controls.start(showAlert ? 'hidden' : 'visible')
  })

  const verifyRecaptcha = async (token) => {
    const endpoint = '/.netlify/functions/verify_recaptcha'
    return axios.post(endpoint, { token }).then(({ data }) => data)
  }

  const handleRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) return
    const token = await executeRecaptcha('contact')
    if (!token) return
    const res = await verifyRecaptcha(token)
    if (res?.action !== 'contact') return
    return res
  }, [executeRecaptcha])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await handleRecaptcha()
      const formData = {
        'form-name': 'contact',
        verified: res?.success ?? false,
        score: res?.score ?? '',
        name,
        email,
        message,
      }
      await axios.post('/', formEncode(formData))
      setShowAlert(true)
    } catch (err) {
      console.log(err)
    }
    setName('')
    setEmail('')
    setMessage('')
  }

  return (
    <>
      <div className="contact-form">
        <div className="py-1">Or use this email form. We'd love to hear from you!</div>
        <Form
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="nobots"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="verified" value="" />
          <input type="hidden" name="score" value="" />
          <label hidden className="d-none" aria-hidden="true">
            <input className="d-none" name="nobots" placeholder="Don't fill this out" />
          </label>
          <Row className="pb-3 text-start">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                name="name"
                placeholder="Your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="pb-3 text-start">
            <Col>
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="pb-3 text-start">
            <Col>
              <Form.Label>Message</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={6}
                name="message"
                placeholder="Your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Col>
          </Row>
          <div className="form-footer">
            <div className="position-relative">
              <Alert
                className="position-absolute w-100 mb-0"
                dismissible
                show={showAlert}
                onClose={() => setShowAlert(false)}
              >
                <div className="alert-heading h5">Message Sent</div>
                <div className="pb-3 fs-6">
                  <span className="d-none d-md-inline">
                    We read all messages and will respond as soon as possible.{' '}
                  </span>
                  Thanks for reaching out!
                </div>
                <Button variant="outline-primary" size="sm" onClick={() => setShowAlert(false)}>
                  Got it
                </Button>
              </Alert>
              <div className="position-absolute w-100">
                <motion.div initial="visible" animate={controls} variants={movAppear}>
                  <Button
                    className="mt-3"
                    variant="outline-primary"
                    disabled={showAlert}
                    type="submit"
                    onClick={(e) => e?.target?.blur()}
                  >
                    Send Message
                  </Button>
                </motion.div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className=" mx-5 mt-2 disclaimer  ">
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </>
  )
}

export default ContactForm
